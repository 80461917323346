import React from 'react'
import { BeautyProsWrapper } from './styles'
import Image from 'next/image'
import OurBPImage from '../../../public/home/GS_home_our_bp.jpeg'
import { Header3, Header2, Text } from '../../styles/Components'
import Button from '../Button'
import { gsColors as c } from '../../styles/Variables'

const aboutBeautyPros = [
  '7 years experience on average',
  'Fully licensed hair and nail artists ',
  'Hundreds of hours of experience required ',
  'Background checked',
]

const OurBeautyPros = () => {
  return (
    <BeautyProsWrapper>
      <div className="image-placeholder">
        <Image
          src={OurBPImage}
          alt="Our Beauty Professionals"
          layout="fill"
          priority
        />
      </div>
      <div className="content">
        <div className="inner">
          <Header3 color={c.salmon}>Get to know</Header3>
          <Header2 color={c.salmon} margin="0 0 60px 0">
            Our beauty pros
          </Header2>
          {aboutBeautyPros.map((v, i, a) => (
            <React.Fragment key={i}>
              <Text
                color={c.salmon}
                fontSize={22}
                spacing={0.22}
                margin="0 0 24px 0"
              >
                {v}
              </Text>
              {i !== a.length - 1 && <div className="bp-divider" />}
            </React.Fragment>
          ))}
          <Button
            large
            outline
            color={c.salmon}
            margin="36px 0 0 0"
            path="book"
          >
            Book Glam
          </Button>
        </div>
      </div>
    </BeautyProsWrapper>
  )
}

export default OurBeautyPros
