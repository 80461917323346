import styled from '@emotion/styled'
import { mq } from '../../../helpers'
import { gsColors as c } from '../../../styles/Variables'

export const HomeWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HeroWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  
  height: 594px;
  aspect-ratio: 16/9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${mq('min', '769px')} {
    height: auto;
  }

  img {
    width: 90%;
    height: auto;  
    z-index: 500;

    ${mq('min', '769px')} {
      width: 70%;
      height: auto;
    }
  }

  h1 {
    z-index: 555;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    object-fit: cover;
    height: 100%;

    ${mq('min', '769px')} {
      object-fit: fill;
      height: auto;      
    }
  }
`

export const MissionWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .mission-inner {
    padding-left: 104px;
    margin: 88px 88px 50px 88px;
    border-left: 1px solid #000;

    ${mq('max', '768px')} {
      margin: 38px 16px;
      padding-left: 30px;
    }
  }
`

export const ServicesWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  padding: 50px 88px;

  ${mq('max', '768px')} {
    padding: 38px 16px;
  }

  .web-carousel-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${mq('max', '768px')} {
      display: none;
    }

  }
  .mobile-carousel-container {
    width: 100%;
    display: flex;
    flex-direction: row;

    ${mq('min', '768px')} {
      display: none;
    }

    .swiper-pagination-bullet {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid #3f3f3f;
      bottom: 0;
    }
    
    .swiper-pagination-bullet-active {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: #000;
      border: 1px solid #000;

    }
  }

  .carousel-service-card {
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-inner {
      align-items: flex-start;

      p {
        text-align: left;
      }
    }

    ${mq('max', '768px')} {
      margin-bottom: 48px;
    }
  }
`

export const CardButton = styled.div`
  width: 100%;
  height: 544px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), 90%, rgba(0,0,0, 1));
    z-index: 30;
  }

  img {
    z-index: 10;
    object-fit: cover;
  }

  
  div {
    z-index: 40;
    width: auto;
    padding: 14px 50px;
    border: 1px solid #FFF;
    margin-bottom: 36px;
  }
`
export const HowToBookWrapper = styled.div`
  width: 100%;  
  padding: 72px 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1600px;

  ${mq('max', '768px')} {
    padding: 32px 79px 50px;
  }

  .mobile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    ${mq('max', '768px')} {
      align-items: flex-start;
      width: auto;
    }

    .how-to-step-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 32px;
  
      ${mq('max', '768px')} {
        flex-direction: column;
        align-items: flex-start;
      }
      
      .how-to-step {
        display: flex;
        flex-direction: row;
        margin-bottom: 18px;
      }
    }
  }

`

export const BeautyProsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 907px;
  max-width: 1600px;

  ${mq('max', '768px')} {
    flex-direction: column;
    height: auto;
  }

  .image-placeholder {
    width: 50%;
    background-color: #4D4D4D;
    position: relative;

    img {
      object-fit: cover;
    }

    ${mq('max', '768px')} {
      height: 498px;
      width: 100%;
    }
  }
  
  .content {
    width: 50%;
    background-color: ${c.cobalt};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    ${mq('max', '768px')} {
      width: 100%;
      align-items: flex-start;
    }

    .inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 16px;

      ${mq('max', '968px')} {
        h3 {
          font-size: 14px;
        }

        h2 {
         font-size: 25px; 
        }

        p {
          font-size: 16.5px;
        }

        button {
          font-size: 14px;
        }
      }
      
      ${mq('max', '768px')} {
        margin: 0;
        width: 100%;
        align-items: flex-start;
        padding: 58px 0;

        button {
          margin-top: 8px;
        }

        h2 {
          margin-bottom: 24px;
        }
      }

      .bp-divider {
        width: 100%;
        border-top: 1px solid ${c.salmon};
        margin-bottom: 24px;

        ${mq('max', '768px')} {
          margin-bottom: 20px;
        }
      }
    }
  }  
`

export const LocationsWrapper = styled.div`
  width: 100%;
  padding: 72px 88px;
  max-width: 1600px;

  ${mq('max', '768px')} {
    padding: 50px 16px;
  }

  .location-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const RatingsWrapper = styled.div`
  width: 100%;
  padding: 50px 16px;
  background-color: ${c.cobalt};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  z-index: 0;

  ${mq('max', '768px')} {
    padding: 38px 16px;
  }

  .swiper-container-container {
    width: 610px;

    ${mq('max', '768px')} {
      width: 100%;
    }

    .swiper-container {
      position: relative;
      padding-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .custom-next-button {
        background-image: url('/images/arrow-right.png');
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        border: none;
        height: 32px;
        width: 32px;
        position: absolute;
        bottom: 0;
        right: 40%;
        z-index: 999;
        cursor: pointer;
      }
      
      .custom-prev-button {
        background-image: url('/images/arrow-left.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        background-color: transparent;
        border: none;
        height: 32px;
        width: 32px;
        position: absolute;
        bottom: 0;
        left: 40%;
        z-index: 999;
        cursor: pointer;
      }
    }


    .rating {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      ${mq('max', '768px')} {
        max-width: 90%;
        margin: 0 auto;
      }
  
      .star-container {
        display: flex;
        flex-direction: row;
        gap: 3px;
        margin-bottom: 12px;
      }
    }
  }
`
