import React from 'react'
import { CardButton } from './styles'
import { Text } from '../../styles/Components'
import Image from 'next/image'
import ServiceImage1 from '../../../public/home/GS_home_service_1.jpg'
import ServiceImage2 from '../../../public/home/GS_home_service_2.jpg'
import ServiceImage3 from '../../../public/home/GS_home_service_3.jpg'

export const asSeenImages = {
  vogue: () => (
    <Image src={require('../../../public/images/VOGUE_LOGO.svg')} alt='vogue' />
  ),
  refinery29: () => (
    <Image src={require('../../../public/images/Refinery29_logo.svg')} alt='refinery29' />
  ),
  gma: () => <Image src={require('../../../public/images/gma.png')} alt='gma' />,
  nyt: () => <Image src={require('../../../public/images/nyt.png')} alt='nyt' />,
  forbes: () => <Image src={require('../../../public/images/forbes.png')} alt='forbes' />,
  inStyle: () => (
    <Image src={require('../../../public/become-a-pro/as-seen/InStyle.jpeg')} alt='inStyle' />
  )
}

export const popularServices = (redirectToBook) => ([
  {
    title: 'Blowouts, buns, and braids',
    description:
      'For hair that bounces from thing to thing, just like you.',
    price: 60,
    clickHandler: () => {
      redirectToBook({ serviceType: 'hair', name: 'blowout' })
    },
    image: () => (
      <CardButton>
        <Image 
          src={ServiceImage1}
          alt='Glamsquad Service' 
          layout='fill'
        />
        <div>
          <Text color='#FFF'>
            Book Hair
          </Text>
        </div>
      </CardButton>
    )
  },
  {
    title: 'Nail maintenance',
    short_title: 'Nails',
    description:
      'Get fresh nails right in your home, hotel, or office.',
    price: 40,
    clickHandler: () => {
      redirectToBook({ serviceType: 'nails', name: 'manicure' })
    },
    image: () => (
      <CardButton>
        <Image 
          src={ServiceImage2}
          alt='Glamsquad Service' 
          layout='fill'
        />
        <div>
          <Text color='#FFF'>
            Book Nails
          </Text>
        </div>
      </CardButton>
    )
  },
  {
    title: 'Makeup for every moment',
    short_title: 'Makeup',
    description:
      'Face events with confidence—and flawless makeup.',
    price: 90,
    clickHandler: () => {
      redirectToBook({ serviceType: 'makeup', name: 'makeup' })
    },
    image: () => (
      <CardButton>
        <Image 
          src={ServiceImage3}
          alt='Glamsquad Service' 
          layout='fill'
        />
        <div>
          <Text color='#FFF'>
            Book Makeup
          </Text>
        </div>
      </CardButton>
    )
  }
])
